import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import BiasChart from './BiasChart';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { selectProjectResults } from '../../project-details/data-access/project.reducer';

// Define the type for bias data items
interface BiasDataItem {
  Attribute: string;
  'Disparity Ratio': number;
  'Calculated Impact'?: number;
  'Bias Detected'?: boolean;
  'External Compliance'?: string;
  'Internal Policy Compliance'?: string;
  Risk?: string;
  Action?: string;
  'Bias Drift'?: string;
  'Intersectional Bias'?: string;
}

export const BiasCard = () => {
  const projectResults = useSelector(selectProjectResults); // Fetch from Redux Store

  const [mlBiasData, setMlBiasData] = useState<BiasDataItem[]>([]);
  const [llmBiasData, setLlmBiasData] = useState<BiasDataItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (projectResults?.results?.bias) {
      // ✅ Use the correct keys from Redux
      const mlData = projectResults.results.bias.ML_Bias_Detection || [];
      const llmData = projectResults.results.bias.LLM_Bias_Detection || [];

      setMlBiasData(mlData);
      setLlmBiasData(llmData);
      setIsLoading(false); // Hide loading indicator after setting data
    }
  }, [projectResults]);

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Row className="g-3">
          <Col xs={12} md={6}>
            <BiasChart
              description={
                'Identifies and quantifies bias in ML models and LLM outputs by analyzing sensitive attributes.'
              }
              title={'BIAS - ML'}
              data={mlBiasData}
            />
          </Col>
          <Col xs={12} md={6}>
            <BiasChart
              description={
                'Identifies and quantifies bias in ML models and LLM outputs by analyzing sensitive attributes.'
              }
              title={'BIAS - LLM'}
              data={llmBiasData}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
