import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ExplainabilityChart from './ExplainabilityChart';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { selectProjectResults } from '../../project-details/data-access/project.reducer';
import ExplainabilityLLMChart from '../Explainability-LLM/ExplainabilityLLMChart';

// Define the type for SHAP Feature Importance Data
interface FeatureImportanceData {
  feature: string;
  importance: number;
  value: number;
}

// Define the type for Token Importance Data
interface TokenImportanceData {
  token: string;
  importance: number;
  value: number;
}

export const ExplainabilityCard = () => {
  const projectResults = useSelector(selectProjectResults); // Fetch from Redux Store

  const [mlFeatureImportance, setMlFeatureImportance] = useState<
    FeatureImportanceData[]
  >([]);

  const [llmFeatureImportance, setLlmFeatureImportance] = useState<
    TokenImportanceData[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (projectResults?.results?.explainability?.LLM_Model_Explainability) {
      const llmExplainability =
        projectResults.results.explainability.LLM_Model_Explainability;

      // Parse SHAP token importance
      const parsedTokens = llmExplainability.top_tokens
        .map((item: string) => {
          const match = item.match(/(.+?)\s\(([\d.]+)\)/);
          return match
            ? {
                token: match[1],
                importance: parseFloat(match[2]),
                value: Math.random()
              }
            : null;
        })
        .filter(Boolean); // Remove null values

      setLlmFeatureImportance(parsedTokens as TokenImportanceData[]);
      setIsLoading(false); // Hide loading indicator
    }
  }, [projectResults]);

  useEffect(() => {
    if (projectResults?.results?.explainability?.ML_Model_Explainability) {
      const mlExplainability =
        projectResults.results.explainability.ML_Model_Explainability;

      // Parse SHAP feature importance
      const parsedFeatures = mlExplainability.top_features
        .map((item: string) => {
          const match = item.match(/(.+?)\s\(([\d.]+)\)/);
          return match
            ? {
                feature: match[1],
                importance: parseFloat(match[2]),
                value: Math.random()
              }
            : null;
        })
        .filter(Boolean); // Remove null values

      setMlFeatureImportance(parsedFeatures as FeatureImportanceData[]);
      setIsLoading(false); // Hide loading indicator
    }
  }, [projectResults]);

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Row className="g-3 mt-4">
          <Col xs={12} md={6}>
            <ExplainabilityChart
              description="Higher importance means greater impact on predictions."
              title="SHAP Feature Importance - ML Model"
              data={mlFeatureImportance}
            />
          </Col>
          <Col xs={12} md={6}>
            <ExplainabilityLLMChart
              description="Higher importance means greater impact on predictions."
              title="SHAP Feature Importance - LLM Model"
              data={llmFeatureImportance}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ExplainabilityCard;
