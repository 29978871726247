import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { UilCube } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: typeof UilCube;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

// Fetch userId dynamically from localStorage
export const getRoutes = (): RouteItems[] => {
  const userId = localStorage.getItem('userId') || ''; // Handle null case

  return [
    {
      label: 'apps',
      icon: UilCube,
      pages: [
        {
          name: 'project-management',
          icon: 'clipboard',
          active: true,
          pages: [
            {
              name: 'Create New',
              path: `/project/${userId}/create-project`,
              pathName: 'project-management-create-new',
              active: true
            },
            {
              name: 'All Projects',
              path: `/project/${userId}/all-projects`,
              pathName: 'project-test-project-list-view',
              active: true
            }
          ]
        },
        {
          name: 'ethico',
          icon: 'clipboard',
          active: true,
          pages: [
            {
              name: 'chat',
              path: `/ethico`,
              pathName: 'chat',
              active: true
            }
          ]
        }
      ]
    }
  ];
};
