import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ProjectSelector } from '../project-list/data-access/+state/project-list.selectors';
import { BiasCard } from './Bias/BiasCard';
import useFetchActiveProject from '../../../hooks/useFetchActiveProject';
import { FairnessCard } from './Fairness/FairnessCard';
import ExplainabilityCard from './Explainability-ML/ExplainabilityCard';

const Dashboard = () => {
  const activeProject = useSelector(ProjectSelector.activeProject);
  useFetchActiveProject();
  return (
    <>
      <div className="pb-5">
        <Row className="g-4">
          <Col xs={12} xxl={12}>
            <div className="mb-4">
              <h4 className="mb-2">Dashboard for {activeProject?.title}</h4>
              <h5 className="text-body-tertiary fw-semibold">
                Here’s what’s going on at your business right now
              </h5>
            </div>
            <BiasCard />
            <FairnessCard />
            <ExplainabilityCard />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
