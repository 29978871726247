import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useAppContext } from '../../../../providers/AppProvider';
import { Card } from 'react-bootstrap';
import Badge from '../../../../components/base/Badge';
import React from 'react';

// Define the interface for data items
interface BiasDataItem {
  Attribute: string;
  'Disparity Ratio': number;
}

interface BiasChartProps {
  data: BiasDataItem[];
  title: string;
  description: string;
}

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

const BiasChart: React.FC<BiasChartProps> = ({ data, title, description }) => {
  const { getThemeColor } = useAppContext();

  // Extract x-axis (Attributes) and y-axis (Disparity Ratios) values from the data
  const attributes = data.map(item => item.Attribute);
  const disparityRatios = data.map(item => item['Disparity Ratio']);

  const options = {
    color: getThemeColor('primary'),
    tooltip: {
      trigger: 'axis',
      backgroundColor: getThemeColor('body-highlight-bg'),
      borderColor: getThemeColor('border-color'),
      textStyle: { color: getThemeColor('light-text-emphasis') },
      borderWidth: 1,
      formatter: (params: any) => {
        const param = params[0];
        return `<strong>${param.name}:</strong> ${param.value}`;
      }
    },
    xAxis: {
      type: 'category',
      data: attributes,
      axisLine: { lineStyle: { color: getThemeColor('border-color') } },
      axisLabel: { color: getThemeColor('secondary-color'), rotate: 45 }
    },
    yAxis: {
      type: 'value',
      min: 0, // Ensures lower values are visible
      max: Math.max(...disparityRatios, 2), // Ensure enough space for the line
      interval: 0.5, // Force 1.0 to be displayed
      splitLine: {
        show: true,
        lineStyle: { type: 'dashed', color: 'rgba(0,0,0,0.3)' }
      },
      axisLine: { lineStyle: { color: getThemeColor('border-color') } },

      axisLabel: {
        color: '#3e465b',
        formatter: '{value}' // Explicitly show all values
      }
    },
    series: [
      {
        type: 'bar',
        data: disparityRatios,
        barWidth: '50%',
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
          color: getThemeColor('primary')
        },
        markLine: {
          silent: false, // Prevent interaction
          lineStyle: {
            type: 'solid',
            color: 'red', // Make it more visible
            width: 2
          },
          label: {
            show: true,
            formatter: 'Threshold: 1.0',
            position: 'insideEndTop',
            color: 'red',
            fontWeight: 'bold',
            fontSize: 12
          },
          data: [{ yAxis: 1.0 }]
        }
      }
    ],
    grid: { left: '10%', right: '10%', bottom: '15%', top: '15%' }
  };
  console.log('ECharts Options:', options);

  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="mb-1">
              {title}
              <Badge bg="warning" variant="phoenix" pill className="ms-2">
                -6.8%
              </Badge>
            </h4>
            <h6 className="text-body-tertiary">{description}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-center px-4 py-6">
          <ReactEChartsCore
            echarts={echarts}
            option={options}
            style={{ height: '400px', width: '100%' }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default BiasChart;
