import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { projectApi } from './project.api'; // Import RTK Query API
import { RootState } from '../../../../app/shared/store'; // Adjust the path if needed

// Define the state type
interface ProjectState {
  projectResults: any | null; // Replace `any` with the actual API response type
  isLoading: boolean;
  error: string | null;
}

// Initial state
const initialState: ProjectState = {
  projectResults: null,
  isLoading: false,
  error: null
};

// Create slice
const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {}, // No need for setProjectResults manually
  extraReducers: builder => {
    builder
      .addMatcher(
        projectApi.endpoints.getProjectResults.matchPending, // Handles API pending state
        state => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addMatcher(
        projectApi.endpoints.getProjectResults.matchFulfilled, // Handles API success response
        (state, action: PayloadAction<any>) => {
          state.projectResults = action.payload; // Automatically update state
          state.isLoading = false;
          state.error = null;
        }
      )
      .addMatcher(
        projectApi.endpoints.getProjectResults.matchRejected, // Handles API failure
        (state, action) => {
          state.isLoading = false;
          state.error = action.error.message || 'Failed to fetch data';
        }
      );
  }
});

// Selector to get project results from state
export const selectProjectResults = (state: RootState) =>
  state.project.projectResults;

// Export reducer
export default projectSlice.reducer;
