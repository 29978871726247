import { useRef, useState, useCallback } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import PhoenixOffcanvas, {
  PhoenixOffcanvasContainer
} from 'components/base/PhoenixOffcanvas';
import ConversationDetails from '../conversation-details';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import ChatContentHeader from './ChatContentHeader';
import ConversationStarter from './ConversationStarter';
import ChatContentFooter from './ChatContentFooter';
import Message from '../message';
import Scrollbar from 'components/base/Scrollbar';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Import the exact types from data/chat
import {
  Message as ChatMessage,
  User as ChatUser,
  Conversation as ChatConversation
} from 'data/chat';

// Define message type for API response
const ChatContent = () => {
  const { userId } = useParams<{ userId: string }>();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [showConversationDetails, setShowConversationDetails] = useState(false);
  const [showUserListOffcanvas] = useState(false);
  const [loading, setLoading] = useState(false);

  const { breakpoints } = useBreakpoints();
  const messageEndRef = useRef<HTMLSpanElement>(null);

  const user: ChatUser = {
    id: 1,
    name: userId || 'User',
    avatar: '',
    status: 'online'
  };

  const currentConversation: ChatConversation = {
    id: 1,
    user: user,
    messages: messages
  };

  const postQuestion = useCallback(async (questionText: string) => {
    if (!questionText) return;

    // Add user message immediately
    const userMessage: ChatMessage = {
      id: Date.now(),
      message: questionText,
      type: 'sent',
      time: new Date().toLocaleTimeString(),
      readAt: null,
      attachments: undefined
    };
    setMessages(prev => [...prev, userMessage]);
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/ethico/ask`,
        {
          question: questionText
        }
      );

      const botResponse: ChatMessage = {
        id: Date.now() + 1,
        message: response.data.response || 'No response received.',
        type: 'received',
        time: new Date().toLocaleTimeString(),
        readAt: null,
        attachments: undefined
      };
      setMessages(prev => [...prev, botResponse]);
    } catch (error) {
      console.error('Error sending question:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Card as={PhoenixOffcanvasContainer} className="h-100 w-100">
      {ChatContentHeader && <ChatContentHeader />}
      <Card.Body className="p-3 p-sm-4 scrollbar d-flex flex-column gap-2">
        {currentConversation.messages.length === 0 && <ConversationStarter />}
        {currentConversation.messages.map(message => (
          <Message
            key={message.id}
            message={message}
            user={currentConversation.user}
          />
        ))}

        {/* Show loading spinner in chat content while waiting for a response */}
        {loading && (
          <div className="text-center mt-3">
            <Spinner animation="border" />
          </div>
        )}

        <span ref={messageEndRef} />
      </Card.Body>

      {/* Removed the loading prop from ChatContentFooter */}
      {ChatContentFooter && <ChatContentFooter onSend={postQuestion} />}

      <PhoenixOffcanvas
        open={showConversationDetails}
        placement="top"
        noBackdrop
        className="bg-body-emphasis w-100 z-index-0 rounded-3"
      >
        <Scrollbar>
          <ConversationDetails
            conversation={currentConversation}
            handleClose={() => setShowConversationDetails(false)}
          />
        </Scrollbar>
      </PhoenixOffcanvas>

      {breakpoints.down('sm') && (
        <PhoenixOffcanvas
          open={showUserListOffcanvas}
          placement="start"
          noBackdrop
          className="w-100 z-index-0"
        />
      )}
    </Card>
  );
};

export default ChatContent;
