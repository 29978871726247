import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PhoenixDocCard from '../../../components/base/PhoenixDocCard';
import { Table } from 'react-bootstrap';
import Badge from '../../../components/base/Badge';
import { selectProjectResults } from '../project-details/data-access/project.reducer';

// Define the type for Fairness data items
interface FairnessDataItem {
  Attribute: string;
  'Disparity Ratio': number;
  'Equal Opportunity'?: number;
  Risk?: string;
  [key: string]: string | number | undefined; // Additional optional fields
}

const FairnessMatrix = () => {
  const projectResults = useSelector(selectProjectResults); // Fetch from Redux Store

  const [mlFairnessData, setMlFairnessData] = useState<FairnessDataItem[]>([]);
  const [llmFairnessData, setLlmFairnessData] = useState<FairnessDataItem[]>(
    []
  );
  const [mlHeaders, setMlHeaders] = useState<string[]>([]);
  const [llmHeaders, setLlmHeaders] = useState<string[]>([]);

  useEffect(() => {
    if (projectResults?.results?.fairness) {
      const mlData: FairnessDataItem[] =
        projectResults.results.fairness.fairness_data_ml || [];
      const llmData: FairnessDataItem[] =
        projectResults.results.fairness.fairness_data_llm || [];

      setMlFairnessData(mlData);
      setLlmFairnessData(llmData);

      if (mlData.length > 0) setMlHeaders(Object.keys(mlData[0]));
      if (llmData.length > 0) setLlmHeaders(Object.keys(llmData[0]));
    }
  }, [projectResults]);

  const renderTableHeaders = (headers: string[]) =>
    headers.map((header, index) => <th key={index}>{header}</th>);

  const getBadgeBg = (risk: string) => {
    switch (risk?.toLowerCase()) {
      case 'low':
        return 'success';
      case 'moderate':
        return 'warning';
      case 'high':
        return 'danger';
      default:
        return 'secondary';
    }
  };

  const renderTableRows = (data: FairnessDataItem[], headers: string[]) =>
    data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {headers.map((header, colIndex) => (
          <td key={colIndex}>
            {header === 'Risk' ? (
              <Badge
                bg={getBadgeBg(row[header] as string)}
                variant="phoenix"
                iconPosition="end"
                className="fs-10"
              >
                {row[header]}
              </Badge>
            ) : typeof row[header] === 'number' ? (
              (row[header] as number).toFixed(2)
            ) : (
              String(row[header] ?? '-')
            )}
          </td>
        ))}
      </tr>
    ));

  return (
    <div>
      <PhoenixDocCard className="mb-5">
        <PhoenixDocCard.Header title="Fairness Metric – Disparate Impact ML Model"></PhoenixDocCard.Header>
        <PhoenixDocCard.Body>
          <Table striped bordered hover>
            <thead>
              <tr>{renderTableHeaders(mlHeaders)}</tr>
            </thead>
            <tbody>{renderTableRows(mlFairnessData, mlHeaders)}</tbody>
          </Table>
        </PhoenixDocCard.Body>
      </PhoenixDocCard>

      <PhoenixDocCard className="mb-5">
        <PhoenixDocCard.Header title="Fairness Metric – Disparate Impact LLM Model"></PhoenixDocCard.Header>
        <PhoenixDocCard.Body>
          <Table striped bordered hover>
            <thead>
              <tr>{renderTableHeaders(llmHeaders)}</tr>
            </thead>
            <tbody>{renderTableRows(llmFairnessData, llmHeaders)}</tbody>
          </Table>
        </PhoenixDocCard.Body>
      </PhoenixDocCard>
    </div>
  );
};

export default FairnessMatrix;
