import { RootState } from '@shared/store'; // Adjust the import path if needed
import { createSelector } from '@reduxjs/toolkit';

// ✅ Ensure correct state reference
export const selectToast = (state: RootState) => state.toast;

// ✅ Correctly structure the selector
export const showToast = createSelector(selectToast, toastState => ({
  message: toastState.message,
  showToast: toastState.show, // Ensure correct property name
  title: toastState.title
}));
