import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import EmojiPicker from 'components/base/EmojiPicker';
import { useChatContext } from 'providers/ChatProvider';
import ReactTextareaAutosize from 'react-textarea-autosize';
import AttachmentPreview from 'components/common/AttachmentPreview';
import { convertFileToAttachment } from 'helpers/utils';
import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { SENT_MESSAGE } from 'reducers/ChatReducer';

interface ChatContentFooterProps {
  onSend?: (questionText: string) => void;
}

const ChatContentFooter = ({ onSend }: ChatContentFooterProps) => {
  const { currentConversation, chatDispatch } = useChatContext();

  const [messageText, setMessageText] = useState('');
  const [fileAttachment, setFileAttachment] = useState<File | null>(null);
  const [imageAttachments, setImageAttachments] = useState<File[]>([]);

  const sentMessage = () => {
    if (!messageText && !fileAttachment && imageAttachments.length === 0) {
      return;
    }

    // If onSend prop is provided, use it to send the message
    if (onSend) {
      onSend(messageText);
      // Reset fields
      setMessageText('');
      setImageAttachments([]);
      setFileAttachment(null);
    } else {
      // Otherwise, use the existing dispatch logic
      if (currentConversation) {
        chatDispatch({
          type: SENT_MESSAGE,
          payload: {
            conversationId: currentConversation.id,
            message: messageText,
            attachments: {
              images: imageAttachments.map(imageAttachment =>
                URL.createObjectURL(imageAttachment)
              ),
              file: fileAttachment
                ? convertFileToAttachment(fileAttachment)
                : undefined
            }
          }
        });
        setMessageText('');
        setImageAttachments([]);
        setFileAttachment(null);
      }
    }
  };

  return (
    <Card.Footer>
      <ReactTextareaAutosize
        minRows={1}
        maxRows={6}
        value={messageText}
        placeholder="Type your message..."
        onChange={({ target: { value } }) => setMessageText(value)}
        className="chat-textarea form-control outline-none border-0 scrollbar resize-none mb-1 p-0 fs-8 fw-normal"
      />

      {fileAttachment && (
        <div className="mb-2">
          <AttachmentPreview
            attachment={convertFileToAttachment(fileAttachment)}
            size="xl"
            handleRemove={() => setFileAttachment(null)}
          />
        </div>
      )}

      {imageAttachments && (
        <div className="mb-2 d-flex gap-2">
          {imageAttachments.map((attachment, index) => (
            <ImageAttachmentPreview
              key={index}
              image={URL.createObjectURL(attachment)}
              handleClose={() => {
                setImageAttachments(
                  imageAttachments.filter((_, i) => index !== i)
                );
              }}
            />
          ))}
        </div>
      )}

      <div className="d-flex gap-3 align-items-center">
        <EmojiPicker
          onSelect={selection => {
            setMessageText(messageText => messageText + selection.emoji);
          }}
        >
          <Button variant="link" className="p-0 text-body fs-9 btn-emoji">
            <FontAwesomeIcon icon={faFaceSmile} />
          </Button>
        </EmojiPicker>

        <Button
          variant="primary"
          endIcon={<FontAwesomeIcon icon={faPaperPlane} className="ms-2" />}
          className="ms-auto"
          type="submit"
          onClick={sentMessage}
        >
          Send
        </Button>
      </div>
    </Card.Footer>
  );
};

export default ChatContentFooter;
