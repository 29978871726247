import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  VisualMapComponent
} from 'echarts/components';
import { ScatterChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useAppContext } from '../../../../providers/AppProvider';
import { Card } from 'react-bootstrap';

echarts.use([
  TooltipComponent,
  GridComponent,
  ScatterChart,
  CanvasRenderer,
  VisualMapComponent
]);

// Define the interface for SHAP Feature Importance Data
interface FeatureImportanceData {
  feature: string;
  importance: number;
  value: number;
}

interface ExplainabilityChartProps {
  data: FeatureImportanceData[];
  title: string;
  description: string;
}

const ExplainabilityChart: React.FC<ExplainabilityChartProps> = ({
  data,
  title,
  description
}) => {
  const { getThemeColor } = useAppContext();

  // Extract y-axis (Features) and x-axis (Feature Importance) values
  const features = data.map(item => item.feature);
  const importanceValues = data.map(item => [
    item.importance,
    item.feature,
    item.value
  ]);

  const options = {
    color: getThemeColor('primary'),
    tooltip: {
      trigger: 'item',
      backgroundColor: getThemeColor('body-highlight-bg'),
      borderColor: getThemeColor('border-color'),
      textStyle: { color: getThemeColor('light-text-emphasis') },
      borderWidth: 1,
      formatter: (params: any) => {
        return `<strong>${
          params.data[1]
        }</strong><br/>Feature Importance: ${params.data[0].toFixed(4)}`;
      }
    },
    xAxis: {
      type: 'value',
      name: 'Feature Importance',
      nameLocation: 'middle',
      nameGap: 25,
      axisLine: { lineStyle: { color: getThemeColor('border-color') } },
      axisLabel: { color: getThemeColor('secondary-color') }
    },
    yAxis: {
      type: 'category',
      data: features,
      name: 'Feature',
      axisLine: { lineStyle: { color: getThemeColor('border-color') } },
      axisLabel: { color: '#3e465b' }
    },
    visualMap: {
      min: 0,
      max: 1,
      dimension: 2, // Feature values for color mapping
      orient: 'vertical',
      right: 10,
      top: 'center',
      calculable: true,
      inRange: {
        color: ['blue', 'purple', 'red'] // Low to high importance gradient
      }
    },
    series: [
      {
        type: 'scatter',
        data: importanceValues,
        symbolSize: 10,
        itemStyle: {
          borderColor: getThemeColor('border-color'),
          borderWidth: 1
        }
      }
    ],
    grid: { left: '10%', right: '10%', bottom: '15%', top: '15%' }
  };

  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="mb-1">{title}</h4>
            <h6 className="text-body-tertiary">{description}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-center px-4 py-6">
          <ReactEChartsCore
            echarts={echarts}
            option={options}
            style={{ height: '400px', width: '100%' }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default ExplainabilityChart;
