import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PhoenixDocCard from '../../../components/base/PhoenixDocCard';
import { Table } from 'react-bootstrap';
import Badge from 'components/base/Badge';
import { selectProjectResults } from '../project-details/data-access/project.reducer';

type BiasDataItem = {
  Attribute: string;
  Risk?: string;
  [key: string]: string | number | undefined;
};

const BiasDetection = () => {
  const projectResults = useSelector(selectProjectResults);

  const [mlBiasData, setMlBiasData] = useState<BiasDataItem[]>([]);
  const [llmBiasData, setLlmBiasData] = useState<BiasDataItem[]>([]);
  const [mlHeaders, setMlHeaders] = useState<string[]>([]);
  const [llmHeaders, setLlmHeaders] = useState<string[]>([]);

  useEffect(() => {
    if (projectResults?.results?.bias) {
      // ✅ Corrected key names
      const mlData: BiasDataItem[] =
        projectResults.results.bias.ML_Bias_Detection || [];
      const llmData: BiasDataItem[] =
        projectResults.results.bias.LLM_Bias_Detection || [];

      setMlBiasData(mlData);
      setLlmBiasData(llmData);

      if (mlData.length > 0) setMlHeaders(Object.keys(mlData[0]));
      if (llmData.length > 0) setLlmHeaders(Object.keys(llmData[0]));
    }
  }, [projectResults]);

  const renderTableHeaders = (headers: string[]) =>
    headers.map((header, index) => <th key={index}>{header}</th>);

  const getBadgeBg = (risk: string | undefined) => {
    switch (risk?.toLowerCase()) {
      case 'low':
        return 'success';
      case 'moderate':
        return 'warning';
      case 'high':
        return 'danger';
      default:
        return 'secondary';
    }
  };

  const renderTableRows = (data: BiasDataItem[], headers: string[]) =>
    data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {headers.map((header, colIndex) => (
          <td key={colIndex}>
            {header === 'Risk' ? (
              <Badge
                bg={getBadgeBg(row[header] as string)}
                variant="phoenix"
                iconPosition="end"
                className="fs-10"
              >
                {row[header]}
              </Badge>
            ) : typeof row[header] === 'number' ? (
              (row[header] as number).toFixed(2)
            ) : (
              String(row[header] ?? '-')
            )}
          </td>
        ))}
      </tr>
    ));

  return (
    <div>
      <PhoenixDocCard className="mb-5">
        <PhoenixDocCard.Header title="BIAS in ML Model"></PhoenixDocCard.Header>
        <PhoenixDocCard.Body>
          <Table striped bordered hover>
            <thead>
              <tr>{renderTableHeaders(mlHeaders)}</tr>
            </thead>
            <tbody>{renderTableRows(mlBiasData, mlHeaders)}</tbody>
          </Table>
        </PhoenixDocCard.Body>
      </PhoenixDocCard>

      <PhoenixDocCard className="mb-5">
        <PhoenixDocCard.Header title="BIAS in LLM Model"></PhoenixDocCard.Header>
        <PhoenixDocCard.Body>
          <Table striped bordered hover>
            <thead>
              <tr>{renderTableHeaders(llmHeaders)}</tr>
            </thead>
            <tbody>{renderTableRows(llmBiasData, llmHeaders)}</tbody>
          </Table>
        </PhoenixDocCard.Body>
      </PhoenixDocCard>
    </div>
  );
};

export default BiasDetection;
