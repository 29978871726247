import { FileAttachment } from 'components/common/AttachmentPreview';
import { Conversation, Message } from 'data/chat';
import dayjs from 'dayjs';
import {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  Dispatch,
  SetStateAction
} from 'react';

interface ChatWidgetProps {
  isOpenChat: boolean;
  conversation: Conversation | null;
  setConversation: Dispatch<SetStateAction<Conversation | null>>;
  setIsOpenChat: Dispatch<SetStateAction<boolean>>;
  sentMessage: ({
    message,
    attachments,
    sender
  }: {
    message?: string;
    attachments?: { images?: string[]; file?: FileAttachment };
    sender?: 'user' | 'bot';
  }) => void;
}

export const ChatWidgetContext = createContext({} as ChatWidgetProps);

const ChatWidgetProvider = ({ children }: PropsWithChildren) => {
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>({
    id: 1, // Temporary ID
    user: {
      id: 1,
      name: 'AI Bot',
      avatar: '/bot-avatar.png',
      status: 'online'
    },
    messages: []
  });

  // ✅ Fetch conversation from API on mount

  // ✅ Send messages and sync with API
  const sentMessage = ({
    message,
    attachments,
    sender = 'user'
  }: {
    message?: string;
    attachments?: { images?: string[]; file?: FileAttachment };
    sender?: 'user' | 'bot';
  }) => {
    console.log('MESSAGE', message);
    if (!conversation) return;

    const newMessage: Message = {
      id: Date.now().toString(),
      message: message || '',
      sender:
        sender === 'user'
          ? conversation.user
          : { name: 'AI Bot', avatar: '/bot-avatar.png' },
      timestamp: dayjs().toISOString(),
      attachments: attachments || {},
      type: sender === 'user' ? 'sent' : 'received',
      readAt: sender === 'bot' ? dayjs().toISOString() : undefined
    };

    setConversation(prevConversation =>
      prevConversation
        ? {
            ...prevConversation,
            messages: [...prevConversation.messages, newMessage] as Message[]
          }
        : null
    );
  };

  return (
    <ChatWidgetContext.Provider
      value={{
        conversation,
        setConversation,
        isOpenChat,
        setIsOpenChat,
        sentMessage
      }}
    >
      {children}
    </ChatWidgetContext.Provider>
  );
};

export const useChatWidgetContext = () => useContext(ChatWidgetContext);

export default ChatWidgetProvider;
