import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  VisualMapComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { useAppContext } from '../../../../providers/AppProvider';
import { Card } from 'react-bootstrap';

echarts.use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  VisualMapComponent
]);

// Define the interface for SHAP Feature Importance Data
interface TokenImportanceData {
  token: string;
  importance: number;
  value: number;
}

interface ExplainabilityLLMChartProps {
  data: TokenImportanceData[];
  title: string;
  description: string;
}

const ExplainabilityLLMChart: React.FC<ExplainabilityLLMChartProps> = ({
  data,
  title,
  description
}) => {
  const { getThemeColor } = useAppContext();

  // Extract y-axis (Tokens) and x-axis (Importance) values
  const tokens = data.map(item => item.token);
  const importanceValues = data.map(item => item.importance);

  const options = {
    color: getThemeColor('primary'),
    tooltip: {
      trigger: 'axis',
      backgroundColor: getThemeColor('body-highlight-bg'),
      borderColor: getThemeColor('border-color'),
      textStyle: { color: getThemeColor('light-text-emphasis') },
      borderWidth: 1,
      formatter: (params: any) => {
        return `<strong>${
          params[0].name
        }</strong><br/>Token Importance: ${params[0].value.toFixed(4)}`;
      }
    },
    xAxis: {
      type: 'value',
      name: 'Token Importance',
      nameLocation: 'middle',
      nameGap: 25,
      axisLine: { lineStyle: { color: getThemeColor('border-color') } },
      axisLabel: { color: getThemeColor('secondary-color') }
    },
    yAxis: {
      type: 'category',
      data: tokens,
      name: 'Token',
      axisLine: { lineStyle: { color: getThemeColor('border-color') } },
      axisLabel: { color: '#3e465b' }
    },
    visualMap: {
      min: Math.min(...importanceValues),
      max: Math.max(...importanceValues),
      dimension: 0,
      orient: 'horizontal',
      left: 'center',
      bottom: 10,
      calculable: true,
      inRange: {
        color: ['blue', 'purple', 'red'] // Low to high importance gradient
      }
    },
    series: [
      {
        type: 'bar',
        data: importanceValues,
        barWidth: '60%',
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
          color: getThemeColor('primary')
        },
        label: {
          show: true,
          position: 'right',
          formatter: '{c}', // Shows importance values on bars
          color: '#3e465b',
          fontWeight: 'bold'
        }
      }
    ],
    grid: { left: '10%', right: '10%', bottom: '20%', top: '10%' }
  };

  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h4 className="mb-1">{title}</h4>
            <h6 className="text-body-tertiary">{description}</h6>
          </div>
        </div>
        <div className="d-flex justify-content-center px-4 py-6">
          <ReactEChartsCore
            echarts={echarts}
            option={options}
            style={{ height: '400px', width: '100%' }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default ExplainabilityLLMChart;
