import { FileAttachment } from 'components/common/AttachmentPreview';
import { Message } from 'data/chat';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ChatState } from 'providers/ChatProvider';
import axios from 'axios';

dayjs.extend(relativeTime);

// Action types
export const SET_CHAT_STATE = 'SET_CHAT_STATE';
export const SENT_MESSAGE = 'SENT_MESSAGE';
export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION';
export const FILTER_CONVERSION_LIST = 'FILTER_CONVERSION_LIST';
export const MARKED_AS_READ = 'MARKED_AS_READ';
export const RESET = 'RESET';

// Action TS Type
export type ACTIONTYPE =
  | { type: typeof SET_CHAT_STATE; payload: Partial<ChatState> }
  | {
      type: typeof SENT_MESSAGE;
      payload: {
        conversationId: number;
        message?: string;
        attachments?: { images?: string[]; file?: FileAttachment };
      };
    }
  | {
      type: typeof SET_CURRENT_CONVERSATION;
      payload: { userId?: number | string };
    }
  | { type: typeof FILTER_CONVERSION_LIST; payload: 'read' | 'unread' | 'all' }
  | { type: typeof MARKED_AS_READ; payload: { conversationId: number } }
  | { type: typeof RESET };

export const chatReducer = (state: ChatState, action: ACTIONTYPE) => {
  switch (action.type) {
    case SET_CHAT_STATE: {
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    }

    case SENT_MESSAGE: {
      const { payload } = action;

      const newMessage: Message = {
        id: Date.now().toString(),
        type: 'sent',
        sender: { name: 'User', avatar: '/user-avatar.png' }, // ✅ Ensure sender is included
        timestamp: dayjs().toISOString(),
        readAt: undefined, // ✅ Fix: Use undefined instead of null
        message: payload.message || '',
        attachments: payload.attachments || {}
      };

      const updatedConversations = state.conversations.map(conversation =>
        conversation.id === payload.conversationId
          ? {
              ...conversation,
              messages: [...conversation.messages, newMessage]
            }
          : conversation
      );

      // ✅ Send message to API
      axios
        .post(
          `http://localhost:8080/api/conversations/${payload.conversationId}/messages`,
          {
            message: payload.message,
            attachments: payload.attachments
          }
        )
        .catch(error => console.error('Error sending message to API:', error));

      return {
        ...state,
        conversations: updatedConversations
      };
    }

    case SET_CURRENT_CONVERSATION: {
      const { payload } = action;
      const conversation = state.conversations.find(
        conversation => conversation.user.id === Number(payload.userId)
      );
      return {
        ...state,
        currentConversation: conversation || null
      };
    }

    case FILTER_CONVERSION_LIST: {
      const { payload } = action;

      axios
        .get(`http://localhost:8080/api/conversations?filter=${payload}`)
        .then(response => {
          return {
            ...state,
            conversations: response.data
          };
        })
        .catch(error => {
          console.error('Error fetching filtered conversations:', error);
          return state;
        });

      return state; // Avoid modifying state immediately, wait for API response
    }

    case MARKED_AS_READ: {
      const { payload } = action;

      const updatedConversations = state.conversations.map(conversation => {
        if (conversation.id === payload.conversationId) {
          return {
            ...conversation,
            messages: conversation.messages.map(message => ({
              ...message,
              readAt: dayjs().toISOString() // ✅ Correct timestamp format
            }))
          };
        }
        return conversation;
      });

      // ✅ Update read status in API
      axios
        .patch(
          `http://localhost:8080/api/conversations/${payload.conversationId}/mark-read`
        )
        .catch(error =>
          console.error('Error marking messages as read:', error)
        );

      return {
        ...state,
        conversations: updatedConversations
      };
    }

    case RESET:
      return {
        ...state
      };

    default:
      return state;
  }
};
