import { useState, useEffect } from 'react';
import { Col, Dropdown, Nav, Row, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsis, faTrash } from '@fortawesome/free-solid-svg-icons';
import Badge from '../../../components/base/Badge';
import Button from '../../../components/base/Button';
import { useMainLayoutContext } from '../../../providers/MainLayoutProvider';
import { useSelector } from 'react-redux';
import { ProjectSelector } from '../project-list/data-access/+state/project-list.selectors';
import BiasDetection from '../Bias/BiasDetection';
import Dashboard from '../Dashboard/Dashboard';
import Fairness from '../Bias/Fairness';
import PromptInjection from '../Bias/PromptInjection';
import { useParams } from 'react-router-dom';
import { selectProjectResults } from './data-access/project.reducer';
import { useGetProjectResultsQuery } from './data-access/project.api';
import ExplainabilityTables from '../Bias/Explainability';

const ProjectDetails = () => {
  const { setContentClass } = useMainLayoutContext();
  const activeProject = useSelector(ProjectSelector.activeProject);
  const [activeTab, setActiveTab] = useState('dashboard'); // Track active tab

  const { projectId } = useParams<{ projectId?: string }>();
  //const userId = localStorage.getItem('userId') ?? ''; // Ensure userId is a string

  useGetProjectResultsQuery(projectId ?? '', { skip: !projectId });

  const projectResults = useSelector(selectProjectResults);

  console.log('PROJECT RESULTS', projectResults);

  useEffect(() => {
    setContentClass('px-0 pt-navbar');
    return () => {
      setContentClass('');
    };
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <Dashboard />;
      case 'bias':
        return <BiasDetection />;
      case 'fairness':
        return <Fairness />;
      case 'explainability':
        return <ExplainabilityTables />;
      case 'abuse':
        return <div>AI Abuse content goes here.</div>;
      case 'prompt_injection':
        return <PromptInjection />;
      default:
        return null;
    }
  };

  return (
    <Row className="g-0">
      <Col xs={12} xxl={12} className="px-0 bg-body">
        <div className="px-4 px-lg-6 pt-6 pb-9">
          <div className="mb-3">
            <Row className="align-items-center justify-content-between g-3 mb-4">
              <Col md="auto">
                <h2 className="mb-0">{activeProject?.title}</h2>
              </Col>
              <Col md="auto" className="d-flex">
                <Button
                  variant="phoenix-secondary"
                  className="px-3 px-sm-5 me-2"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-sm-2" />
                  <span className="d-none d-sm-inline">Upload Files</span>
                </Button>
                <Button
                  variant="phoenix-danger"
                  className="me-2"
                  startIcon={
                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                  }
                >
                  <span>Delete Project</span>
                </Button>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="phoenix-secondary"
                    className="px-3 dropdown-caret-none"
                  >
                    <FontAwesomeIcon icon={faEllipsis} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="py-2" style={{ zIndex: 9999 }}>
                    <Dropdown.Item href="#!">View profile</Dropdown.Item>
                    <Dropdown.Item href="#!">Report</Dropdown.Item>
                    <Dropdown.Item href="#!">
                      Manage notifications
                    </Dropdown.Item>
                    <Dropdown.Item href="#!" className="text-danger">
                      Delete Lead
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Badge
              variant="phoenix"
              bg="primary"
              iconPosition="end"
              icon={<FontAwesomeIcon icon={faEdit} className="ms-1" />}
            >
              Completed
            </Badge>
          </div>
          <Row className="gx-0 gx-sm-5 gy-8 mb-8">
            <Col xs={12} xl={12} xxl={12} className="pe-xl-0">
              <Tab.Container
                id="basic-tabs-example"
                activeKey={activeTab}
                onSelect={key => setActiveTab(key || 'dashboard')}
              >
                <Nav variant="underline" className="mb-3">
                  <Nav.Item>
                    <Nav.Link eventKey="dashboard">Dashboard</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="bias">Bias Detection</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fairness">Fairness Metrics</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="explainability">
                      Explainability
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="abuse">AI Abuse</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="prompt_injection">
                      Prompt Injection
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  {renderTabContent()} {/* Dynamically render the active tab */}
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default ProjectDetails;
