import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PhoenixDocCard from '../../../components/base/PhoenixDocCard';
import { Table } from 'react-bootstrap';
import { selectProjectResults } from '../project-details/data-access/project.reducer';

type ExplainabilityDataItem = {
  Feature: string;
  Importance: number;
  [key: string]: string | number | undefined;
};

const ExplainabilityTables = () => {
  const projectResults = useSelector(selectProjectResults);

  const [mlExplainabilityData, setMlExplainabilityData] = useState<
    ExplainabilityDataItem[]
  >([]);
  const [llmExplainabilityData, setLlmExplainabilityData] = useState<
    ExplainabilityDataItem[]
  >([]);
  const [mlHeaders, setMlHeaders] = useState<string[]>([]);
  const [llmHeaders, setLlmHeaders] = useState<string[]>([]);

  useEffect(() => {
    if (projectResults?.results?.explainability) {
      const mlExplainability =
        projectResults.results.explainability.ML_Model_Explainability;
      const llmExplainability =
        projectResults.results.explainability.LLM_Model_Explainability;

      // Parse ML Explainability Data
      if (mlExplainability?.top_features) {
        const mlData = mlExplainability.top_features
          .map((item: string) => {
            const match = item.match(/(.+?)\s\(([\d.]+)\)/);
            return match
              ? { Feature: match[1], Importance: parseFloat(match[2]) }
              : null;
          })
          .filter(Boolean);

        setMlExplainabilityData(mlData as ExplainabilityDataItem[]);
        if (mlData.length > 0)
          setMlHeaders(Object.keys(mlData[0] as ExplainabilityDataItem));
      }

      // Parse LLM Explainability Data
      if (llmExplainability?.top_tokens) {
        const llmData = llmExplainability.top_tokens
          .map((item: string) => {
            const match = item.match(/(.+?)\s\(([\d.]+)\)/);
            return match
              ? { Feature: match[1], Importance: parseFloat(match[2]) }
              : null;
          })
          .filter(Boolean);

        setLlmExplainabilityData(llmData as ExplainabilityDataItem[]);
        if (llmData.length > 0)
          setLlmHeaders(Object.keys(llmData[0] as ExplainabilityDataItem));
      }
    }
  }, [projectResults]);

  const renderTableHeaders = (headers: string[]) =>
    headers.map((header, index) => <th key={index}>{header}</th>);

  const renderTableRows = (data: ExplainabilityDataItem[], headers: string[]) =>
    data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {headers.map((header, colIndex) => (
          <td key={colIndex}>
            {typeof row[header] === 'number'
              ? (row[header] as number).toFixed(4)
              : String(row[header] ?? '-')}
          </td>
        ))}
      </tr>
    ));

  return (
    <div>
      {/* ML Explainability Table */}
      <PhoenixDocCard className="mb-5">
        <PhoenixDocCard.Header title="Explainability - ML Model"></PhoenixDocCard.Header>
        <PhoenixDocCard.Body>
          <Table striped bordered hover>
            <thead>
              <tr>{renderTableHeaders(mlHeaders)}</tr>
            </thead>
            <tbody>{renderTableRows(mlExplainabilityData, mlHeaders)}</tbody>
          </Table>
        </PhoenixDocCard.Body>
      </PhoenixDocCard>

      {/* LLM Explainability Table */}
      <PhoenixDocCard className="mb-5">
        <PhoenixDocCard.Header title="Explainability - LLM Model"></PhoenixDocCard.Header>
        <PhoenixDocCard.Body>
          <Table striped bordered hover>
            <thead>
              <tr>{renderTableHeaders(llmHeaders)}</tr>
            </thead>
            <tbody>{renderTableRows(llmExplainabilityData, llmHeaders)}</tbody>
          </Table>
        </PhoenixDocCard.Body>
      </PhoenixDocCard>
    </div>
  );
};

export default ExplainabilityTables;
