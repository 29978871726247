import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ProjectSelector } from '../project-list/data-access/+state/project-list.selectors';
import PhoenixDocCard from '../../../components/base/PhoenixDocCard';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Table } from 'react-bootstrap';
import useFetchActiveProject from '../../../hooks/useFetchActiveProject';
import Badge from '../../../components/base/Badge';

// Define the type for Prompt Injection Data items
interface PromptInjectionDataItem {
  Prompt: string;
  'Injection Detected': boolean;
  'Compliance Issue (Internal)': boolean;
  'Validation Details': string;
  'Suggested Action': string;
  [key: string]: string | boolean; // ✅ Add this index signature
}

const PromptInjection = () => {
  const [promptData, setPromptData] = useState<PromptInjectionDataItem[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const activeProject = useSelector(ProjectSelector.activeProject);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userId = localStorage.getItem('userId');

  useFetchActiveProject();

  useEffect(() => {
    if (userId && activeProject) {
      setIsLoading(true);
      const fetchPromptInjectionData = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/project/features/prompt-validation`,
            { userId: userId, projectId: activeProject.projectId }
          );

          const data = response.data;
          if (data && data.length > 0) {
            setPromptData(data);
            setHeaders(Object.keys(data[0]));
          }
        } catch (error) {
          console.error('Error fetching prompt injection data:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchPromptInjectionData();
    }
  }, [activeProject, userId]);

  const renderTableHeaders = (headers: string[]) =>
    headers.map((header, index) => <th key={index}>{header}</th>);

  const getBadgeBg = (detected: boolean) => (detected ? 'danger' : 'success');

  const renderTableRows = (
    data: PromptInjectionDataItem[],
    headers: string[]
  ) =>
    data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {headers.map((header, colIndex) => (
          <td key={colIndex}>
            {header === 'Injection Detected' ||
            header === 'Compliance Issue (Internal)' ? (
              <Badge
                bg={getBadgeBg(row[header] as boolean)} // ✅ Type assertion
                variant="phoenix"
                iconPosition="end"
                className="fs-10"
              >
                {row[header] ? 'Yes' : 'No'}
              </Badge>
            ) : (
              String(row[header] as string) // ✅ Type assertion
            )}
          </td>
        ))}
      </tr>
    ));

  return (
    <div>
      <PhoenixDocCard className="mb-5">
        <PhoenixDocCard.Header title="Prompt Injection Detection"></PhoenixDocCard.Header>
        <PhoenixDocCard.Body>
          <Table striped bordered hover>
            <thead>
              <tr>{renderTableHeaders(headers)}</tr>
            </thead>
            <tbody>{renderTableRows(promptData, headers)}</tbody>
          </Table>
        </PhoenixDocCard.Body>
      </PhoenixDocCard>
      <Backdrop
        sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default PromptInjection;
