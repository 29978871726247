import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import FairnessChart from './FairnessChart';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { selectProjectResults } from '../../project-details/data-access/project.reducer';

// Define the interface for fairness data
interface FairnessDataItem {
  Attribute: string;
  'Disparity Ratio': number;
  'Equal Opportunity': number;
  Risk: string;
}

export const FairnessCard = () => {
  const projectResults = useSelector(selectProjectResults); // Fetch from Redux Store

  const [mlFairnessData, setMlFairnessData] = useState<FairnessDataItem[]>([]);
  const [llmFairnessData, setLlmFairnessData] = useState<FairnessDataItem[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (projectResults?.results?.fairness) {
      const mlData = projectResults.results.fairness.fairness_data_ml || [];
      const llmData = projectResults.results.fairness.fairness_data_llm || [];

      setMlFairnessData(mlData);
      setLlmFairnessData(llmData);
      setIsLoading(false); // Hide loading indicator after setting data
    }
  }, [projectResults]);

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Row className="g-3 mt-4">
          <Col xs={12} md={6}>
            <FairnessChart
              description={
                'Identifies and quantifies bias in ML models and LLM outputs by analyzing sensitive attributes.'
              }
              title={'Fairness - ML'}
              data={mlFairnessData}
            />
          </Col>
          <Col xs={12} md={6}>
            <FairnessChart
              description={
                'Identifies and quantifies bias in ML models and LLM outputs by analyzing sensitive attributes.'
              }
              title={'Fairness - LLM'}
              data={llmFairnessData}
            />
          </Col>
        </Row>
      )}
    </>
  );
};
