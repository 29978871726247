const ConversationStarter = () => {
  return (
    <div className="d-flex align-items-end justify-content-center text-center h-100">
      <div>
        This is the beginning of your private chat with Ethico <br />
        Ask your questions here
        <span className="fa-solid fa-paper-plane text-primary ms-1"></span>
      </div>
    </div>
  );
};

export default ConversationStarter;
