import ChatContent from 'components/modules/chat/chat-content';

const ChatConversation = () => {
  //const { chatDispatch, conversations } = useChatContext();

  return (
    <>
      <ChatContent />
    </>
  );
};

export default ChatConversation;
