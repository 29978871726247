import { combineReducers, configureStore } from '@reduxjs/toolkit';
import projectReducer from '../../pages/project-management/project-details/data-access/project.reducer';
import { projectApi } from '../../pages/project-management/project-details/data-access/project.api';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../shared/+state/+root/root.saga';
import { accountSlice } from '../../pages/authentication/data-access/+state/account.reducer';
import { createProjectSlice } from '../../pages/project-management/create-project/data-access/+state/project-create.reducer';
import { projectListSlice } from '../../pages/project-management/project-list/data-access/+state/project-list.reducer';
import { toastSlice } from '../../components/Toasts/data-access/toast-reducer';

// Initialize saga middleware
const sagaMiddleware = createSagaMiddleware();
// Combine all reducers
export const rootReducer = combineReducers({
  project: projectReducer,
  [projectApi.reducerPath]: projectApi.reducer,
  [createProjectSlice.name]: createProjectSlice.reducer,
  [projectListSlice.name]: projectListSlice.reducer,
  [accountSlice.name]: accountSlice.reducer,
  [toastSlice.name]: toastSlice.reducer
});

// Define RootState type
export type RootState = ReturnType<typeof rootReducer>;

// Configure the store
export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false // Disable serializable checks
    })
      .concat(projectApi.middleware)
      .concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);
// Export types for use throughout the app
export type AppDispatch = typeof store.dispatch;

// Export the store
export default store;
